import { appApi } from "app/api";
import {
  dataTransformSchema,
  enumString,
  nullableString,
  requiredString,
  requiredUuid,
} from "app/schema";
import { Place, placeSchema } from "features/place";
import { userPreviewSchema } from "features/user";
import * as yup from "yup";

export const proximitySchema = enumString("single", "midpoint").required();

export const placeCategorySchema = enumString(
  "food",
  "drink",
  "dessert"
).required();

export const priceSchema = enumString(
  "low",
  "moderate",
  "expensive",
  "veryExpensive"
).required();

export const roomStatusSchema = enumString(
  "new",
  "voting",
  "completed"
).required();

export const roomMemberStatusSchema = enumString(
  "active",
  "voted",
  "changed_vote",
  "archived",
  "inactive"
).required();

export const roomPlaceVoteSchema = yup.object({
  id: requiredUuid,
  user: userPreviewSchema,
});

export const roomPlaceSchema = yup.object({
  id: requiredUuid,
  roomId: requiredUuid,
  placeId: requiredUuid,
  userId: requiredUuid,
  createdAt: requiredString,
  place: placeSchema,
  user: userPreviewSchema,
  votes: yup.array().of(roomPlaceVoteSchema),
});

export const roomMemberSchema = yup.object({
  id: requiredUuid,
  roomId: requiredUuid,
  userId: requiredUuid,
  status: roomMemberStatusSchema,
  user: userPreviewSchema,
});

export const roomSchema = yup.object({
  id: requiredUuid,
  name: nullableString,
  hostId: requiredUuid,
  placeCategory: placeCategorySchema.nullable(),
  proximity: proximitySchema.nullable(),
  prices: yup.array().of(priceSchema).nullable(),
  createdAt: requiredString,
  updatedAt: requiredString,
  status: roomStatusSchema,
  votingExpiredAt: nullableString,
  members: yup.array().of(roomMemberSchema),
});

export type Proximity = yup.Asserts<typeof proximitySchema>;
export type PlaceCategory = yup.Asserts<typeof placeCategorySchema>;
export type Price = yup.Asserts<typeof priceSchema>;
export type RoomStatus = yup.Asserts<typeof roomStatusSchema>;
export type RoomMemberStatus = yup.Asserts<typeof roomMemberStatusSchema>;
export type RoomMember = yup.Asserts<typeof roomMemberSchema>;
export type RoomPlaceVote = yup.Asserts<typeof roomPlaceVoteSchema>;
export type RoomPlace = yup.Asserts<typeof roomPlaceSchema>;
export type Room = yup.Asserts<typeof roomSchema>;

const roomApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoom: builder.query<Room, string>({
      query: (roomId) => `/rooms/${roomId}`,
      extraOptions: {
        schema: dataTransformSchema(roomSchema),
      },
    }),
    listRoomPlaces: builder.query<RoomPlace[], string>({
      query: (roomId) => `/rooms/${roomId}/places`,
      extraOptions: {
        schema: dataTransformSchema(yup.array().of(roomPlaceSchema)),
      },
    }),
    listRoomTopPlaces: builder.query<RoomPlace[], string>({
      query: (roomId) => `/rooms/${roomId}/places/top`,
      extraOptions: {
        schema: dataTransformSchema(yup.array().of(roomPlaceSchema)),
      },
    }),
  }),
});

export const {
  getRoom: { useQuery: useGetRoom, useLazyQuery: useGetRoomLazy },
  listRoomPlaces: { useQuery: useListRoomPlaces },
  listRoomTopPlaces: { useQuery: useListRoomTopPlaces },
} = roomApi.endpoints;
