import { enumString } from "app/schema";
import { Timestamp } from "firebase/firestore";
import * as yup from "yup";

export const messageContentTypeSchema = enumString(
  "text",
  "image",
  "place"
).required();

export type MessageContentType = yup.Asserts<typeof messageContentTypeSchema>;

export interface FirestoreMessage {
  roomId: string;
  id: string;
  senderId: string;
  createdAt: Timestamp;
  isAutomated: boolean;
  contentType: MessageContentType;
  contentId?: string;
  content?: string;
}
