import { skipToken } from "@reduxjs/toolkit/dist/query";
import { LoadingIndicator } from "app/LoadingIndicator";
import { useGetUrl } from "features/url";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const RedirectPage = () => {
  const { hash } = useParams();

  const { data: url } = useGetUrl(hash ?? skipToken);
  const navigate = useNavigate();

  useEffect(() => {
    const path = url?.path;
    console.log("path", path);
    if (path) {
      navigate(path);
    }
  }, [url, navigate]);

  return <LoadingIndicator />;
};
