import { Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { RoomDetail, useListRoomPlaces } from "features/room";
import { RoomPlaceRow } from "./RoomPlaceRow";

export interface RoomShortlistPageProps {
  roomDetail?: RoomDetail;
}

export const RoomShortlistPage = ({ roomDetail }: RoomShortlistPageProps) => {
  const { data: roomPlaces } = useListRoomPlaces(
    roomDetail?.room.id ?? skipToken
  );

  console.log("roomdeta", roomPlaces);

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      {roomPlaces?.map((x) => (
        <RoomPlaceRow roomPlace={x} />
      ))}
    </Stack>
  );
};
