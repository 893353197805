import { ApiResponse, appApi } from "app/api";
import {
  dataTransformSchema,
  nullableBoolean,
  nullableNumber,
  nullableString,
  requiredString,
  requiredUuid,
  stringArray,
} from "app/schema";
import { tagSchema } from "features/tag";
import * as yup from "yup";

export const placePreviewSchema = yup.object({
  id: requiredUuid,
  name: requiredString,
  city: nullableString,
  state: nullableString,
  imageUrl: nullableString,
  rating: nullableString,
  reviewCount: nullableNumber,
});

export const placeSchema = yup.object({
  id: requiredUuid,
  name: requiredString,
  location: requiredString,
  city: nullableString,
  state: nullableString,
  country: nullableString,

  // Google PLace
  website: nullableString,
  utcOffset: nullableNumber,
  phoneNumber: nullableString,
  googlePlaceId: nullableString,
  summary: nullableString,
  hoursWeekdayText: stringArray.nullable(),
  lat: nullableNumber,
  lng: nullableNumber,

  // Yelp
  imageUrl: nullableString,
  isYelpSynced: nullableBoolean,
  price: nullableString,
  rating: nullableString,
  reviewCount: nullableNumber,
  yelpUrl: nullableString,

  createdAt: requiredString,
  updatedAt: requiredString,

  photoUrls: stringArray.nullable(),

  tags: yup.array().of(tagSchema),
});

const createPlaceRequestSchema = yup.object({
  googlePlaceId: requiredString,
});

export interface Place extends yup.Asserts<typeof placeSchema> {}
export interface CreatePlaceRequest
  extends yup.Asserts<typeof createPlaceRequestSchema> {}

export interface PlacePreview extends yup.Asserts<typeof placePreviewSchema> {}

const placeApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    listPlaces: builder.query<Place[], void>({
      query: () => "/places",
      extraOptions: {
        schema: dataTransformSchema(yup.array().of(placeSchema)),
      },
    }),
    getPlacePreview: builder.query<PlacePreview, string>({
      query: (placeId) => `/places/${placeId}/preview`,
      extraOptions: {
        schema: dataTransformSchema(placePreviewSchema),
      },
    }),
    createPlace: builder.mutation<ApiResponse<Place>, CreatePlaceRequest>({
      query: (body) => ({
        url: "/places",
        method: "POST",
        body: createPlaceRequestSchema.cast(body),
        extraOptions: {
          schema: dataTransformSchema(placeSchema),
        },
      }),
    }),
  }),
});

export const {
  listPlaces: { useQuery: useListPlaces },
  getPlacePreview: { useQuery: useGetPlacePreview },
  createPlace: { useMutation: useCreatePlace },
} = placeApi.endpoints;

// class PlaceService {
//   private axiosInstance: AxiosInstance;

//   constructor() {
//     const axiosInstance = axios.create({
//       baseURL: Const.Api.BaseUrl,
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       },
//     });

//     axiosInstance.interceptors.request.use(
//       (config) => {
//         const identity = authManager.getIdentity();

//         if (identity) {
//           const { idToken } = identity;
//           (config.headers as any).Authorization = `Bearer ${idToken}`;
//         }

//         console.log("request config", config);
//         return config;
//       },
//       (error) => {
//         // console.log("request error", error);
//         return Promise.reject(error);
//       }
//     );

//     this.axiosInstance = axiosInstance;
//   }

//   async list() {
//     const { data } = await this.axiosInstance.get("/places");
//     return data.data as Place[];
//   }

//   /**
//    * Create an Olive place from Google place DTO
//    * @param dto
//    * @returns
//    */
//   async create(googlePlaceId: string) {
//     const { data } = await this.axiosInstance.post("/places", {
//       googlePlaceId,
//     });
//     return data.data as Place;
//   }
// }
