import { Stack, Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { SideNav } from "features/navigation/SideNav";

export const Layout: React.FC = () => {
  return (
    <Stack>
      <Header />
      <Box sx={{ height: 32 }} />
      <Box sx={{ display: "flex" }}>
        <SideNav />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            pl: { xs: 3, md: 12 },
            pb: { xs: 12, md: 0 },
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <Footer />
    </Stack>
  );
};
