import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  Stack,
  SxProps,
} from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useConnection } from "features/connections";
import {
  useListenFirestoreRooms,
  useGetRoomLazy,
  RoomDetail,
  FirestoreRoom,
} from "features/room";
import React, { useEffect, useState } from "react";
import { RoomRow } from "./RoomRow/RoomRow";
import { Theme } from "@emotion/react";

export interface RoomListNavProps {
  selectedRoom?: RoomDetail;
  onSelect: (roomDetail: RoomDetail) => void;
  sx?: SxProps<Theme>;
}

export const RoomListNav = ({
  selectedRoom,
  onSelect,
  sx,
}: RoomListNavProps) => {
  const { session } = useConnection();
  const { data: firestoreRooms } = useListenFirestoreRooms(
    session?.userId ?? skipToken
  );
  const [getRoom] = useGetRoomLazy();

  const [roomDetails, setRoomDetails] = useState<RoomDetail[]>([]);

  useEffect(() => {
    const fetch = async () => {
      if (!firestoreRooms) {
        return;
      }

      const firestoreRoomsByRoomId = new Map<string, FirestoreRoom>();
      firestoreRooms.forEach((x) => firestoreRoomsByRoomId.set(x.id, x));

      const rooms = await Promise.all(
        firestoreRooms.map((x) => getRoom(x.id).unwrap())
      );

      const details: RoomDetail[] = rooms.map((room) => ({
        firestoreRoom: firestoreRoomsByRoomId.get(room.id)!,
        room,
      }));

      setRoomDetails(details);
    };

    if ((firestoreRooms?.length ?? 0) > 0) {
      fetch();
    } else {
      setRoomDetails([]);
    }
  }, [firestoreRooms, getRoom]);

  return (
    <List sx={sx}>
      {roomDetails.map((x) => (
        <React.Fragment key={x.room.id}>
          <ListItemButton
            key={x.room.id}
            sx={{
              background:
                selectedRoom?.room.id === x.firestoreRoom.id
                  ? "#a69fd71f"
                  : "#FFF",
              "&:hover": {
                background: "#a69fd71f",
              },
            }}
            onClick={() => onSelect(x)}
          >
            <ListItem>
              <RoomRow roomDetail={x} />
            </ListItem>
          </ListItemButton>
          <Divider key={`${x.room.id}-divider`} component="li" />
        </React.Fragment>
      ))}
    </List>
  );
};
