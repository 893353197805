import { getFirstName } from "features/user";
import { Room } from "./room.api";
import { FirestoreRoom } from "./room.firestore.api";

export interface RoomDetail {
  room: Room;
  firestoreRoom: FirestoreRoom;
}

export const getDisplayTitle = (room: Room, currentUserId: string) => {
  if (room.name ?? "".length > 0) {
    return room.name;
  }

  const user = room.members?.find((x) => x.userId !== currentUserId)?.user;

  if (!user) {
    return;
  }

  return getFirstName(user);
};

export const getSubtitle = (firestoreRoom: FirestoreRoom) => {
  const message = firestoreRoom.lastMessage;

  if (!message) {
    return "No Content";
  }

  switch (message.contentType) {
    case "text":
      return message.content;
    case "place":
      return "New Place Added";
    case "image":
      return "New Image added";
  }
};
