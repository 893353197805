// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBuR4k7qFXK4EwyTNoB2Pj2ipBuTb_wceg",
  authDomain: "olive-dev-389307.firebaseapp.com",
  projectId: "olive-dev-389307",
  storageBucket: "olive-dev-389307.appspot.com",
  messagingSenderId: "1011923164896",
  appId: "1:1011923164896:web:7fb5d7c2fba4c592f0ba85",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);
