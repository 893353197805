import { Divider, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { MessageInputBar } from "features/chat";
import { ChatView } from "features/chat/ChatView";
import { RoomDetail, useListenMessages } from "features/room";
import { useState } from "react";

export interface RoomChatPageProps {
  roomDetail?: RoomDetail;
}

export const RoomChatPage = ({ roomDetail }: RoomChatPageProps) => {
  const { data: firestoreMessages } = useListenMessages(
    roomDetail?.room.id ?? skipToken
  );

  const [loading, setLoading] = useState(false);

  const handleSend = async (message: string) => {
    setLoading(true);
  };

  return (
    <Stack sx={{ position: "relative", height: "calc(100vh - 110px)" }}>
      <ChatView
        messages={firestoreMessages}
        users={(roomDetail?.room.members ?? []).map((x) => x.user)}
        sx={{ overflowY: "scroll" }}
      />
      <MessageInputBar loading={loading} onSend={handleSend} />
    </Stack>
  );
};
