import { AppBar, Box, Button, Stack, Toolbar, Typography } from "@mui/material";
import { useConnection } from "features/connections";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const navigate = useNavigate();
  const { clearSession } = useConnection();

  const handleLogout = () => {
    clearSession();
    navigate("/");
  };

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      elevation={0}
    >
      <Toolbar variant="dense">
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ backgroundColor: "primary.main" }}
          spacing={2}
        >
          <Box
            sx={{
              width: 24,
              height: 24,
              borderRadius: "50%",
              background: "#E3FDAA",
            }}
          />
          <Typography
            variant="h4"
            sx={{
              color: "#F6FFE8",
              fontSize: "20px",
              fontFamily: "PPMoriSemiBold",
            }}
          >
            Moonshotz
          </Typography>
          {/* <Button
            variant="text"
            sx={{ color: "#F6FFE8", fontSize: "12px" }}
            onClick={handleLogout}
          >
            Log Out
          </Button> */}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
