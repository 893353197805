import {
  CssBaseline,
  IconButton,
  Tabs,
  Tab,
  Stack,
  Tooltip,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Typography,
} from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import SettingsIcon from "@mui/icons-material/Settings";

import { a11yProps } from "component/core";
import { useColorMode } from "features/theme";
import { useTabNavigation } from "./useTabNavigation";
import { useState } from "react";
import Logout from "@mui/icons-material/Logout";
import { useConnection } from "features/connections";
import { useNavigate } from "react-router-dom";

export interface SideNavProps {}

export const SideNav = () => {
  const { toggleColorMode, isDarkMode } = useColorMode();
  const { tabs, currentTabIndex, setCurrentTabIndex } = useTabNavigation();
  const navigate = useNavigate();
  const { clearSession, session } = useConnection();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    clearSession();
    navigate("/");
  };

  if (!session) {
    return <></>;
  }

  return (
    <Stack
      direction="column"
      position="fixed"
      sx={{
        display: { xs: "none", md: "block" },
        pt: 2,
        height: "calc(100vh - 55px)",
      }}
    >
      <CssBaseline />
      <Tabs
        variant="fullWidth"
        orientation="vertical"
        value={currentTabIndex}
        onChange={(event, newValue) => setCurrentTabIndex(newValue)}
        aria-label="basic tabs example"
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          pt: 1,
        }}
      >
        {tabs.map((x, index) => (
          <Tab
            key={x.title}
            sx={{
              fontSize: "11px",
              textTransform: "none",
              fontFamily: "PPMoriSemiBold",
            }}
            label={x.title}
            icon={x.icon}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          sx={{ position: "absolute", bottom: { xs: 80, md: 12 }, left: 16 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          width: 400,
          position: "fixed",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mb: 2.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              bottom: "10px",
              left: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem key="top" onClick={toggleColorMode}>
          {isDarkMode ? (
            <>
              <ListItemIcon>
                <Brightness7Icon fontSize="small" />
              </ListItemIcon>
              <Typography variant="subtitle2">Light</Typography>
            </>
          ) : (
            <>
              <ListItemIcon>
                <Brightness4Icon fontSize="small" />
              </ListItemIcon>
              <Typography variant="subtitle2">Dark</Typography>
            </>
          )}
        </MenuItem>
        <Divider />
        <MenuItem key="bottom" onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Typography variant="subtitle2">Logout</Typography>
        </MenuItem>
      </Menu>
      {/* <IconButton
        onClick={() => toggleColorMode()}
        color="inherit"
      >
        
      </IconButton> */}
    </Stack>
  );
};
