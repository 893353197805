import { ApiResponse, appApi } from "app/api";
import {
  dataTransformSchema,
  nullableString,
  requiredString,
  requiredUuid,
} from "app/schema";
import * as yup from "yup";

export const urlCreateRequestSchema = yup.object({
  path: requiredString,
});

export const urlSchema = yup.object({
  id: requiredUuid,
  path: requiredString,
  hash: nullableString,
  createdAt: requiredString,
});

export interface Url extends yup.Asserts<typeof urlSchema> {}
export interface UrlCreateRequest
  extends yup.Asserts<typeof urlCreateRequestSchema> {}

const userApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    createUrl: builder.mutation<ApiResponse<Url>, UrlCreateRequest>({
      query: (body) => ({
        url: "urls",
        method: "POST",
        body: urlCreateRequestSchema.cast(body),
        extraOptions: {
          schema: dataTransformSchema(urlSchema),
        },
      }),
    }),
    getUrl: builder.query<Url, string>({
      query: (hash) => `/urls/${hash}`,
      extraOptions: {
        schema: dataTransformSchema(urlSchema),
      },
    }),
  }),
});

export const {
  getUrl: { useQuery: useGetUrl },
  createUrl: { useMutation: useCreateUrl },
} = userApi.endpoints;
