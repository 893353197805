import { Stack, SxProps, Typography } from "@mui/material";
import { Place } from "./place.api";
import { TagChip, TagChipSize } from "features/tag";
import { Theme } from "@emotion/react";

export interface PlaceRowContentProps {
  place: Place;
  showTag: boolean;
  sx?: SxProps<Theme>;
}

export const PlaceRowContent = ({
  place,
  showTag,
  sx,
}: PlaceRowContentProps) => {
  const { name, city, state, tags } = place;
  return (
    <Stack direction="row" spacing={1} sx={sx}>
      <img
        src={place.imageUrl ?? ""}
        alt={place.name}
        width={50}
        height={50}
        style={{ objectFit: "cover", background: "blue", borderRadius: 12 }}
      />
      <Stack>
        <Typography variant="h6">{name}</Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {city}, {state}
        </Typography>
        {showTag && (
          <Stack direction="row" spacing={1} sx={{ pt: 0.5 }}>
            {tags?.map((x) => (
              <TagChip size={TagChipSize.small} tag={x} />
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
