import { appApi } from "app/api";
import { dataTransformSchema, requiredString, requiredUuid } from "app/schema";
import * as yup from "yup";

export const mfaRequestSchema = yup.object({
  phoneNumber: requiredString,
});

const mfaRequiredResponseSchema = yup.object({
  challengeName: requiredString,
  challengeParameters: yup.object({
    CODE_DELIVERY_DELIVERY_MEDIUM: requiredString,
    CODE_DELIVERY_DESTINATION: requiredString,
  }),
  session: requiredString,
});

const loginRequestSchema = yup.object({
  phoneNumber: requiredString,
  confirmationCode: requiredString,
  session: requiredString,
  challengeName: requiredString,
});

const loginResponseSchema = yup.object({
  userId: requiredString,
  accessToken: requiredString,
  idToken: requiredString,
  refreshToken: requiredString,
});

const responseSchema = yup.object({
  userId: requiredUuid,
  accessToken: requiredString,
  idToken: requiredString,
  refreshToken: requiredString,
});

export const refreshTokenResponseSchema = yup.object({
  accessToken: requiredString,
  idToken: requiredString,
});

export interface MfaRequest extends yup.Asserts<typeof mfaRequestSchema> {}
export interface MfaRequiredResponse
  extends yup.Asserts<typeof mfaRequiredResponseSchema> {}

export interface LoginRequest extends yup.Asserts<typeof loginRequestSchema> {}
export interface LoginResponse
  extends yup.Asserts<typeof loginResponseSchema> {}

export interface AuthenticationResult
  extends yup.Asserts<typeof responseSchema> {}
export interface RefreshTokenResult
  extends yup.Asserts<typeof refreshTokenResponseSchema> {}

export interface RefreshTokenRequest {
  token: string | null;
}

export const authenticationApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    startMfaLogin: builder.mutation<MfaRequiredResponse, MfaRequest>({
      query: (request) => ({
        url: "users/mfa",
        method: "POST",
        body: mfaRequestSchema.cast(request),
      }),
      extraOptions: {
        schema: dataTransformSchema(mfaRequiredResponseSchema),
      },
    }),

    completeMfaLogin: builder.mutation<LoginResponse, LoginRequest>({
      query: (request) => ({
        url: "users/mfa-login",
        method: "POST",
        body: loginRequestSchema.cast(request),
      }),
      extraOptions: {
        schema: dataTransformSchema(loginResponseSchema),
      },
    }),

    refreshToken: builder.mutation<
      RefreshTokenResult,
      RefreshTokenRequest | void
    >({
      query: (data) => ({
        url: "users/refresh",
        method: "POST",
        body: {
          refreshToken: data?.token,
        },
        // credentials: "include",
      }),
      extraOptions: {
        authenticate: false,
        schema: dataTransformSchema(refreshTokenResponseSchema),
      },
    }),
    revokeToken: builder.mutation<void, void>({
      query: () => ({
        url: "users/revoke",
        method: "DELETE",
        // credentials: "include",
      }),
    }),
  }),
});

export const {
  startMfaLogin: { useMutation: useStartMfaLogin },
  completeMfaLogin: { useMutation: useCompleteMfaLogin },
  refreshToken: { useMutation: useTokenRefresh },
  revokeToken: { useMutation: useTokenRevoke },
} = authenticationApi.endpoints;
