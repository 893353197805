import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useRef, useState } from "react";
import { Place } from "features/place";
import { ListPlace, isListPlace } from "features/list";

interface Props {
  item: Place | ListPlace;
  isEditingAllowed: boolean;
  onNoteUpdate?: (placeId: string, note: string) => Promise<void>;
  onDelete?: (id: string) => Promise<void>;
}

export const PlaceItem = ({
  item,
  isEditingAllowed,
  onNoteUpdate,
  onDelete,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [note, setNote] = useState((isListPlace(item) ? item.note : "") ?? "");
  const textRef = useRef<HTMLInputElement>(null);

  const visitCount = isListPlace(item) ? item.visit?.visitCount ?? 0 : 1;

  const { id, imageUrl, name, location, tags } = isListPlace(item)
    ? item.place
    : item;

  useEffect(() => {
    if (isEditing && textRef) {
      textRef.current?.select();
    }
  }, [isEditing, textRef]);

  const handleSave = () => {
    setIsEditing(false);
    onNoteUpdate?.(id, note);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setNote((isListPlace(item) ? item.note : "") ?? "");
  };

  return <></>;
  // <Card sx={{ background: "primary.main" }} elevation={0}>
  //   <Stack spacing={2}>
  //     <Stack spacing={2}>
  //       <Stack direction="row" spacing={2} alignItems="start">
  //         {imageUrl ? (
  //           <img
  //             src={imageUrl}
  //             alt=""
  //             style={{ width: 30, height: 30, borderRadius: 15 }}
  //           />
  //         ) : (
  //           <Box
  //             sx={{
  //               borderRadius: 15,
  //               width: 30,
  //               height: 30,
  //               backgroundSize: "15px",
  //               backgroundColor: "#386A20",
  //               backgroundPosition: "center",
  //               backgroundImage: "url('/star.light.png')",
  //               backgroundRepeat: "no-repeat",
  //             }}
  //           />
  //         )}
  //         <Stack sx={{ flex: 1 }}>
  //           <Typography variant="h5">{name}</Typography>
  //           <Typography sx={{ fontSize: "14px" }}>{location}</Typography>
  //           {!isEditing && note && (
  //             <Typography sx={{ fontSize: "14px", mt: 1, mb: 1 }}>
  //               "{note}"
  //             </Typography>
  //           )}
  //         </Stack>
  //         {isEditingAllowed && !isEditing && (
  //           <Tooltip title="Add Note">
  //             <IconButton size="small" onClick={() => setIsEditing(true)}>
  //               <EditIcon />
  //             </IconButton>
  //           </Tooltip>
  //         )}
  //         {/* {isHovering && onDelete && (
  //           <Tooltip title="Delete">
  //             <IconButton size="small" onClick={() => onDelete(id)}>
  //               <DeleteIcon />
  //             </IconButton>
  //           </Tooltip>
  //         )} */}
  //       </Stack>
  //       {isEditing && (
  //         <Stack>
  //           <TextField
  //             value={note}
  //             inputRef={textRef}
  //             onChange={(event) => setNote(event.target.value)}
  //           />
  //           <Stack direction="row" justifyContent="end">
  //             <Button
  //               variant="text"
  //               sx={{
  //                 color: "#6750A4",
  //                 fontSize: "12px",
  //                 fontWeight: "bold",
  //               }}
  //               onClick={handleCancel}
  //             >
  //               Cancel
  //             </Button>
  //             <Button
  //               variant="text"
  //               sx={{
  //                 color: "#6750A4",
  //                 fontSize: "12px",
  //                 fontWeight: "bold",
  //               }}
  //               onClick={handleSave}
  //             >
  //               Save
  //             </Button>
  //           </Stack>
  //         </Stack>
  //       )}
  //       <Stack
  //         direction="row"
  //         sx={{ flexWrap: "wrap", rowGap: 2 }}
  //         justifyItems="start"
  //       >
  //         {tags.map(({ title }) => (
  //           <Chip
  //             key={title}
  //             label={title}
  //             size="small"
  //             color="primary"
  //             sx={{ mr: 1 }}
  //           />
  //         ))}
  //         {visitCount > 1 && (
  //           <Chip
  //             key="visit"
  //             label={`${visitCount} visits`}
  //             size="small"
  //             sx={{ mr: 1, background: "#052100", color: "#fff" }}
  //           />
  //         )}
  //       </Stack>
  //       <Stack direction="row" spacing={1}></Stack>
  //     </Stack>
  //     <Divider />
  //   </Stack>
  // </Card>
  // );
};
