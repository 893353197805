import { Stack, SxProps } from "@mui/material";
import { FirestoreMessage } from "./message";
import { MessageCell } from "./MessageCell/MessageCell";
import { useConnection } from "features/connections";
import { UserPreview } from "features/user";
import { Theme } from "@emotion/react";
import { useEffect, useRef } from "react";

export interface ChatViewProps {
  messages?: FirestoreMessage[];
  users: UserPreview[];
  sx?: SxProps<Theme>;
}

export const ChatView = ({ messages, users, sx }: ChatViewProps) => {
  const { session } = useConnection();

  const lastMessageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView();
    }
  }, [lastMessageRef]);

  if (!messages) {
    return <></>;
  }

  return (
    <Stack sx={{ p: 2, pb: 15, ...sx }} spacing={5}>
      {messages.map((x) => {
        const user = users.find((u) => u.id === x.senderId);
        const isCurrentUser = user?.id === session?.userId;
        return (
          <MessageCell message={x} user={user} isCurrentUser={isCurrentUser} />
        );
      })}
      {messages.length > 0 && <div ref={lastMessageRef} />}
    </Stack>
  );
};
