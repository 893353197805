import { Avatar, SxProps } from "@mui/material";
import { UserPreview } from "./user.api";
import { getInitials } from "./user";
import { Theme } from "@emotion/react";

export enum UserAvatarSize {
  xsmall,
  small,
  medium,
  mediumLarge,
  large,
}

const UserAvatarFont = {
  [UserAvatarSize.xsmall]: "9px",
  [UserAvatarSize.small]: "12px",
  [UserAvatarSize.medium]: "14px",
  [UserAvatarSize.mediumLarge]: "16px",
  [UserAvatarSize.large]: "16px",
};

const UserAvatarDimension = {
  [UserAvatarSize.xsmall]: 20,
  [UserAvatarSize.small]: 32,
  [UserAvatarSize.medium]: 42,
  [UserAvatarSize.mediumLarge]: 50,
  [UserAvatarSize.large]: 68,
};

export interface UserAvatarProps {
  user: UserPreview;
  size: UserAvatarSize;
  sx?: SxProps<Theme>;
}

export const UserAvatar = ({ user, size, sx }: UserAvatarProps) => {
  return user.imageUrl ? (
    <Avatar
      src={user.imageUrl}
      sx={{
        width: UserAvatarDimension[size],
        height: UserAvatarDimension[size],
        ...sx,
      }}
    />
  ) : (
    <Avatar
      sx={{
        bgcolor: "gray",
        fontSize: UserAvatarFont[size],
        width: UserAvatarDimension[size],
        height: UserAvatarDimension[size],
        ...sx,
      }}
    >
      {getInitials(user)}
    </Avatar>
  );
};
