import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { useListUsers } from "features/user";
import { LoadingIndicator } from "app/LoadingIndicator";
import { plural } from "features/language";
import { ShareSheet } from "component/core/ShareSheet";
import { useState } from "react";
import { useConnection } from "features/connections";

export default function FriendListsPage() {
  const { session } = useConnection();
  const { data: users, isLoading } = useListUsers();
  const [isShareSheetOpen, setShareSheetOpen] = useState(false);

  if (isLoading) {
    return <LoadingIndicator />;
  }
  console.log("UserDetailPage", users);
  return (
    <Stack>
      <Stack spacing={2} alignItems="center">
        <Typography
          variant="body1"
          textAlign="center"
          sx={{
            width: { xs: "90%", md: 400 },
          }}
        >
          Share Moonshootz with your friends to make your recommendations even
          more personalized -- they will automatically become your friend when
          they sign up
        </Typography>
        <Button
          variant="contained"
          onClick={() => setShareSheetOpen(true)}
          sx={{
            borderRadius: 10,
            background: "#82BA65",
            color: "#000",
            textTransform: "none",
            fontWeight: "bold",
            p: 1,
            width: { xs: "90%", md: 400 },
            "&:hover": {
              background: "#82BA65",
            },
          }}
        >
          Share Moonshotz
        </Button>
      </Stack>

      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {users?.map((x) => (
          <ListItem
            key={x.id}
            sx={{
              cursor: "pointer",
              "&:hover": { backgroundColor: "background.active" },
            }}
          >
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: "primary.main" }}>
                <img src="/personality.png" alt="" width={25} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={x.name}
              secondary={
                <>
                  {/* <Typography variant="subtitle2">
                    {`${x.tasteProfile?.cityCount ?? 0} ${plural(
                      x.tasteProfile?.cityCount ?? 0,
                      "city",
                      "cities"
                    )}`}{" "}
                    |{" "}
                    {`${x.tasteProfile?.placeCount ?? 0} ${plural(
                      x.tasteProfile?.placeCount ?? 0,
                      "place"
                    )}`}
                  </Typography> */}
                  <Typography variant="caption">{x.email}</Typography>
                </>
              }
            />
          </ListItem>
        ))}
      </List>

      <ShareSheet
        path={`/users/${session?.userId}`}
        isOpen={isShareSheetOpen}
        onDismiss={() => setShareSheetOpen(false)}
      />
    </Stack>
  );
}
