import { Stack, Typography } from "@mui/material";
import { useConnection } from "features/connections";
import {
  RoomDetail,
  RoomRowMembers,
  getDisplayTitle,
  getSubtitle,
} from "features/room";
import { useMemo } from "react";
import { RoomStatusChip } from "./RoomStatusChip";

export interface RoomRowProps {
  roomDetail: RoomDetail;
}

export const RoomRow = ({ roomDetail }: RoomRowProps) => {
  const { session } = useConnection();

  const { room, firestoreRoom } = roomDetail;

  const members = useMemo(
    () => room.members?.filter((x) => x.userId !== session?.userId) ?? [],
    [session, room]
  );
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <RoomRowMembers members={members} />
      <Stack spacing={1}>
        <Typography variant="body1" sx={{ fontFamily: "PPMoriSemiBold" }}>
          {getDisplayTitle(room, session!.userId)}
        </Typography>
        <RoomStatusChip status={room.status} />
        <Typography variant="body1">{getSubtitle(firestoreRoom)}</Typography>
      </Stack>
    </Stack>
  );
};
