import { UserPreview } from "features/user";
import { FirestoreMessage } from "../message";
import { MessageAutomatedTextCell } from "./MessageAutomatedTextCell";
import { MessagePlaceCell } from "./MessagePlaceCell";
import { MessageTextCell } from "./MessageTextCell";

export interface MessageCellProps {
  message: FirestoreMessage;
  user?: UserPreview;
  isCurrentUser: boolean;
}

export const MessageCell = (props: MessageCellProps) => {
  const {
    message: { contentType, isAutomated },
  } = props;
  if (contentType === "text") {
    return isAutomated ? (
      <MessageAutomatedTextCell {...props} />
    ) : (
      <MessageTextCell {...props} />
    );
  }

  if (contentType === "place") {
    return <MessagePlaceCell {...props} />;
  }

  return <></>;
};
