import { Paper, Stack, Typography } from "@mui/material";
import { PlaceRowContent } from "features/place";
import { RoomPlace } from "features/room";
import { UserAvatar, UserAvatarSize, getFirstName } from "features/user";

export interface RoomPlaceRowProps {
  roomPlace: RoomPlace;
}

export const RoomPlaceRow = ({ roomPlace }: RoomPlaceRowProps) => {
  const { place, user } = roomPlace;

  return (
    <Stack sx={{ borderRadius: 4, border: "1px solid #dfdfdf", maxWidth: 500 }}>
      <PlaceRowContent place={place} showTag sx={{ p: 2 }} />
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ backgroundColor: "#EBEAF0", p: 2 }}
      >
        <UserAvatar user={user} size={UserAvatarSize.small} />
        <Typography sx={{}}>Added by {getFirstName(user)}</Typography>
      </Stack>
    </Stack>
  );
};
