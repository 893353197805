import { Chip } from "@mui/material";
import { Tag } from "features/tag";

export enum TagChipSize {
  small,
  normal,
}

const Font = {
  [TagChipSize.small]: 9,
  [TagChipSize.normal]: 12,
};

export interface TagChipProps {
  tag: Tag;
  size: TagChipSize;
}

export const TagChip = ({ tag, size }: TagChipProps) => {
  return (
    <Chip
      sx={(theme) => ({
        color: "#FFF",
        background: theme.palette.secondary.main,
        fontSize: "11px",
        fontFamily: "PPMoriSemiBold",
      })}
      label={tag.title}
      size="small"
    />
  );
};
