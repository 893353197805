import { appApi } from "app/api";
import {
  dataTransformSchema,
  enumString,
  nullableString,
  requiredNumber,
  requiredString,
  requiredUuid,
  stringArray,
} from "app/schema";
import { Place, placeSchema } from "features/place";
import { tasteProfileSchema } from "features/taste-profile/taste-profile.api";
import * as yup from "yup";

export const userStatusSchema = enumString(
  "invited",
  "onboarding",
  "active",
  "inactive"
).required();

export const userPreviewSchema = yup.object({
  id: requiredUuid,
  name: nullableString,
  username: nullableString,
  imageUrl: nullableString,
  tagline: nullableString,
  status: userStatusSchema,
});

export const userSchema = yup.object({
  id: requiredUuid,
  email: nullableString,
  imageUrl: nullableString,
  tagline: requiredString,
  username: requiredString,
  name: requiredString,
  status: userStatusSchema,
  unreadNotificationsCount: requiredNumber,
  phoneNumber: nullableString,
  location: nullableString,
  friendCount: requiredNumber,
  viewedCoachMarks: stringArray,
  createdAt: requiredString,
  updatedAt: requiredString,
});

export interface User extends yup.Asserts<typeof userSchema> {}
export interface UserPreview extends yup.Asserts<typeof userPreviewSchema> {}

export type UserStatus = yup.Asserts<typeof userStatusSchema>;

const userApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<User, void>({
      query: () => "/users/me",
      extraOptions: {
        schema: dataTransformSchema(userSchema),
      },
    }),
    getUser: builder.query<User, string>({
      query: (userId) => `/users/${userId}`,
      extraOptions: {
        schema: dataTransformSchema(userSchema),
      },
    }),
    listUsers: builder.query<User[], void>({
      query: (userId) => "/users",
      extraOptions: {
        schema: dataTransformSchema(yup.array().of(userSchema)),
      },
    }),
    getLoginUrl: builder.query<string, void>({
      query: () => "/users/login",
      extraOptions: {
        schema: dataTransformSchema(requiredString),
      },
    }),
    listUserPlaces: builder.query<Place[], string>({
      query: (userId) => `/users/${userId}/places`,
      extraOptions: {
        schema: dataTransformSchema(yup.array().of(placeSchema)),
      },
    }),
  }),
});

export const {
  getMe: { useQuery: useGetMe },
  getUser: { useQuery: useGetUser },
  listUsers: { useQuery: useListUsers },
  getLoginUrl: { useQuery: useGetLoginUrl },
  listUserPlaces: { useQuery: useGetUserPlaces },
} = userApi.endpoints;

// class UserService extends ApiService {
//   async getMe() {
//     const {
//       data: { data },
//     } = await this.axiosInstance.get("/users/me");

//     return data as User;
//   }

//   async get(id: string) {
//     const {
//       data: { data },
//     } = await this.axiosInstance.get(`/users/${id}`);

//     return data as User;
//   }

//   getLoginUrl = async () => {
//     const { data } = await this.axiosInstance.get("/users/login");
//     return data.data.data as string;
//   };
// }
