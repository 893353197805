import { Theme } from "@emotion/react";
import { Stack, SxProps, Tab, Tabs, Typography } from "@mui/material";
import { TabPanel } from "component/core";
import { RoomDetail } from "features/room";
import { useState } from "react";
import { RoomChatPage } from "./RoomChatPage/RoomChatPage";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { RoomShortlistPage } from "./RoomShortlistPage/RoomShortlistPage";
export interface RoomDetailTabPageProps {
  roomDetail?: RoomDetail;
  sx?: SxProps<Theme>;
}

export const RoomDetailTabPage = ({
  roomDetail,
  sx,
}: RoomDetailTabPageProps) => {
  const [tab, setTab] = useState(0);

  if (!roomDetail) {
    return (
      <Stack sx={sx}>
        <Typography>No room selected</Typography>
      </Stack>
    );
  }

  return (
    <Stack sx={sx}>
      <Tabs
        variant="fullWidth"
        value={tab}
        onChange={(event, newValue) => setTab(newValue)}
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tab icon={<ChatBubbleIcon />} iconPosition="start" label="Chat" />
        <Tab
          icon={<FormatListBulletedIcon />}
          iconPosition="start"
          label="Shortlist"
        />
      </Tabs>

      <TabPanel value={tab} index={0}>
        <RoomChatPage roomDetail={roomDetail} />
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <RoomShortlistPage roomDetail={roomDetail} />
      </TabPanel>
    </Stack>
  );
};
