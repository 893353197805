import { createContext, useState, useContext, ReactNode } from "react";
import { Group, Person2, Search, Forum } from "@mui/icons-material";

export interface TabItem {
  title: string;
  icon: ReactNode;
}

const tabs = [
  { title: "Rooms", icon: <Forum /> },
  {
    title: "Discover",
    icon: <Search />,
  },
  {
    title: "Friends",
    icon: <Group />,
  },
  {
    title: "Profile",
    icon: <Person2 />,
  },
];

const Context = createContext({
  currentTabIndex: 0,
  setCurrentTabIndex: (index: number) => {},
  tabs,
});

export interface TabNavigationContextProps {
  children: ReactNode;
}

export function TabNavigationContextProvider({
  children,
}: TabNavigationContextProps) {
  const [currentTabIndex, setCurrentTabIndex] = useState(3);

  return (
    <Context.Provider value={{ currentTabIndex, setCurrentTabIndex, tabs }}>
      {children}
    </Context.Provider>
  );
}

export const useTabNavigation = () => useContext(Context);
