import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { PrimaryColor } from "features/theme";
import {
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";

interface SendButtonProps {
  visible: boolean;
  loading: boolean;
  onClick: () => Promise<void>;
}

const SendButton = ({ visible, loading, onClick }: SendButtonProps) => {
  return loading ? (
    <CircularProgress sx={{ color: PrimaryColor, mr: 1 }} size="24px" />
  ) : (
    <IconButton
      sx={{ color: PrimaryColor, opacity: visible ? 1 : 0 }}
      onClick={onClick}
    >
      <SendIcon />
    </IconButton>
  );
};

export interface MessageInputBarProps {
  loading: boolean;
  onSend: (message: string) => Promise<void>;
}

export const MessageInputBar = ({ loading, onSend }: MessageInputBarProps) => {
  const [message, setMessage] = useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    send();
  };

  const send = async () => {
    const santizedMessage = message.trim();
    onSend(santizedMessage);
    setMessage("");
  };

  return (
    <Stack
      spacing={2}
      sx={{
        position: "absolute",
        bottom: 0,
        pb: 3,
        width: "100%",
        background: "#FFF",
      }}
    >
      <Divider />
      <Stack
        direction="row"
        sx={{
          background: "#FFF",
          pr: 4,
        }}
        alignItems="center"
        spacing={2}
        component="form"
        onSubmit={handleSubmit}
      >
        <IconButton
          size="medium"
          sx={{ border: `1px solid ${PrimaryColor}`, color: PrimaryColor }}
        >
          <CheckIcon fontSize="medium" />
        </IconButton>
        <IconButton
          size="medium"
          sx={{
            background: PrimaryColor,
            color: "#FFF",
            "&:hover": {
              background: PrimaryColor,
              color: "#FFF",
            },
          }}
        >
          <AddIcon fontSize="medium" />
        </IconButton>
        <TextField
          placeholder="Say something..."
          sx={{ flex: 1 }}
          autoComplete="off"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          InputProps={{
            sx: { borderRadius: 4 },
            endAdornment: (
              <SendButton
                loading={loading}
                visible={message.trim().length > 0}
                onClick={() => send()}
              />
            ),
          }}
        />
      </Stack>
    </Stack>
  );
};
