import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import LandingPage from "features/onboarding/pages/LandingPage";
import VerifyPage from "features/onboarding/pages/VerifyPage";
import { useConnection, useExistingSessionCheck } from "features/connections";
import { LoadingIndicator } from "app/LoadingIndicator";
import ValuePropPage from "features/onboarding/pages/ValuePropPage";
import UserListsPage from "features/user/pages/UserListsPage";
import HomePage from "features/user/pages/HomePage/HomePage";
import ListDetailPage from "features/list/pages/ListDetailPage";
import { Login } from "@mui/icons-material";
import { Layout } from "pages/components/Layout";
import PrivacyPage from "features/onboarding/pages/PrivacyPage";
import { ThemeProvider } from "@mui/material";
import { useColorMode } from "features/theme";
import { TabNavigationContextProvider } from "features/navigation/useTabNavigation";
import { RedirectPage } from "pages/url/RedirectPage";
import { SplashLoginPage } from "pages/splash/SplashLoginPage";
import { RoomListPage } from "pages/room/RoomListPage";

const App: React.FC = () => {
  const { session } = useConnection();
  const { isComplete: sessionCheckComplete } = useExistingSessionCheck();

  const { theme } = useColorMode();

  if (!sessionCheckComplete) return <LoadingIndicator />;

  console.log("theme", theme);
  return (
    <ThemeProvider theme={theme}>
      <TabNavigationContextProvider>
        <Router>
          <Routes>
            {/* <Route index element={<Navigate to="login" replace={true} />} /> */}
            {/* <Route index element={<LandingPage />} /> */}
            {/* <Route index element={<DashboardBoardPage />} /> */}
            <Route index element={<SplashLoginPage />} />
            {/* <Route path="rooms" element={<Layout />}>
              <Route index element={<RoomListPage />} />
            </Route> */}
            <Route path="verify" element={<VerifyPage />} />
            <Route path="login" element={<ValuePropPage />} />
            <Route path="privacy" element={<PrivacyPage />} />
            <Route path="s">
              <Route path=":hash" element={<RedirectPage />} />
            </Route>
            <Route path="home" element={<Layout />}>
              <Route index element={<HomePage />} />
            </Route>
            {/* <Route path="users" element={<Layout />}>
              <Route path=":userId" element={<UserDetailpage />}>
                <Route path="lists" element={<UserListsPage />} /> 
              </Route>
            </Route>  */}
            <Route path="lists" element={<Layout />}>
              <Route path=":listId" element={<ListDetailPage />} />
            </Route>
            <Route path="login" element={<Login />} />
          </Routes>
          <Outlet />
        </Router>
      </TabNavigationContextProvider>
    </ThemeProvider>
  );
};

export default App;

// export const App: React.FC = () => {
//   const router = createBrowserRouter([
//     {
//       path: "/users",
//       element: <Layout />,
//       children: [
//         {
//           element: <UserDetailPage />,
//           path: ":userId",
//           loader: profileLoader,
//           children: [
//             {
//               element: <UserListsPage />,
//               path: "lists",
//               loader: userListsLoader,
//             },
//           ],
//         },
//       ],
//     },
//     {
//       path: "/lists",
//       element: <Layout />,
//       children: [
//         {
//           element: <ListDetailPage />,
//           path: ":listId",
//           loader: listLoader,
//         },
//       ],
//     },
//     {
//       path: "/verify",
//       element: <VerifyPage />,
//     },
//     {
//       path: "/",
//       element: <LandingPage />,
//     },
//     {
//       path: "/login",
//       loader: valuePropLoader,
//       element: <ValuePropPage />,
//     },
//   ]);

//   return <RouterProvider router={router} />;
// };
