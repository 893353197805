import {
  UserAvatar,
  UserAvatarSize,
  UserPreview,
  getFirstName,
} from "features/user";
import { FirestoreMessage } from "../message";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import { useGetPlacePreview } from "features/place";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import StarIcon from "@mui/icons-material/Star";

export interface MessagePlaceCellProps {
  message: FirestoreMessage;
  user?: UserPreview;
  isCurrentUser: boolean;
}

export const MessagePlaceCell = ({
  message,
  user,
  isCurrentUser,
}: MessagePlaceCellProps) => {
  const { data: place } = useGetPlacePreview(message.contentId ?? skipToken);

  if (!place) {
    return <Box sx={{ p: 18, background: "#fff" }}></Box>;
  }

  const { imageUrl, name, city, state, reviewCount, rating } = place;

  return (
    <Stack alignItems={isCurrentUser ? "end" : "start"}>
      <Stack
        direction={isCurrentUser ? "row-reverse" : "row"}
        alignItems="end"
        spacing={2}
      >
        <UserAvatar user={user!} size={UserAvatarSize.small} />
        <Stack spacing={2}>
          <Typography>{getFirstName(user!)} Added a place</Typography>
          <Card sx={{ width: 280 }}>
            <CardMedia
              sx={{ height: 150 }}
              image={imageUrl ?? ""}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {name}
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {city}, {state}
              </Typography>
              <Stack direction="row" alignItems="center">
                <Typography variant="body1">{rating}</Typography>
                <StarIcon sx={{ color: "gold" }} />
                <Typography variant="body1">({reviewCount})</Typography>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Stack>
    </Stack>
  );
};
