import { UserPreview } from "./user.api";

export const getFirstName = (user: UserPreview) => {
  const names = user.name?.split(" ") ?? [];

  if (names.length > 0) {
    return names[0];
  }
};

export const getInitials = (user: UserPreview) => {
  const names = user.name?.split(" ") ?? [];

  if (names.length > 1) {
    const [firstName, lastName] = names;
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  }
};
