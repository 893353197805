import { Avatar, Badge, Box } from "@mui/material";
import { RoomMember } from "./room.api";
import { UserAvatar, UserAvatarSize } from "features/user";

export interface RoomRowMembersProps {
  // Does not include current user
  members: RoomMember[];
}

export const RoomRowMembers = ({ members }: RoomRowMembersProps) => {
  const memberCount = members.length - 2;

  if (members.length === 0) {
    return <Avatar sx={{ width: 50, height: 50 }}>N</Avatar>;
  } else if (members.length === 1) {
    return (
      <UserAvatar user={members[0].user} size={UserAvatarSize.mediumLarge} />
    );
  } else {
    return (
      <Box sx={{ position: "relative", width: 50, height: 50 }}>
        <UserAvatar
          user={members[0].user}
          size={UserAvatarSize.small}
          sx={{ position: "absolute", top: 4, left: 2 }}
        />
        <UserAvatar
          user={members[1].user}
          size={UserAvatarSize.small}
          sx={{ position: "absolute", right: 2, bottom: 4 }}
        />
        {memberCount > 0 && (
          <Badge
            badgeContent={`+${memberCount}`}
            color="primary"
            sx={{ position: "absolute", right: 8, bottom: 8 }}
          />
        )}
      </Box>
    );
  }
};
