import { useParams } from "react-router-dom";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import {
  UserAvatar,
  UserAvatarSize,
  useGetUser,
  useGetUserPlaces,
} from "features/user";
import { useGetTasteProfile } from "features/taste-profile/taste-profile.api";
import { useCombinedQueryState } from "app/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { LoadingIndicator } from "app/LoadingIndicator";
import { PlaceRowContent } from "features/place";
import { MapPage } from "features/map/MapPage";

export interface UserDetailPageProps {
  userId?: string;
}

export const UserDetailPage = ({ userId }: UserDetailPageProps) => {
  // const { userId } = useParams();
  const { data: user, ...userQuery } = useGetUser(userId ?? skipToken);
  const { data: places, ...userPlaceQuery } = useGetUserPlaces(
    userId ?? skipToken
  );

  console.log("we", user, places);

  const handleGoogleMapsLoaded = () => {};

  const { isLoading } = useCombinedQueryState(userQuery, userPlaceQuery);

  if (isLoading || !user) {
    return <LoadingIndicator />;
  }

  return (
    <Grid container>
      <Helmet>
        <title>{user?.name ?? ""}</title>
        <meta property="og:title" content={user?.name ?? ""} />
        <meta name="og:description" content="Come check out my taste profile" />
        <meta
          property="og:image"
          content="https://main.d2k4al3gq1ayn4.amplifyapp.com/logo.png"
        />
      </Helmet>
      <Grid item xs={12} sm={4}>
        <Stack sx={{ p: 2, overflow: "scroll", height: "calc(100vh - 50px)" }}>
          <Stack direction="row" spacing={2} alignItems="center" sx={{ pb: 3 }}>
            <UserAvatar user={user} size={UserAvatarSize.large} />
            <Typography variant="h4">{user.name}</Typography>
          </Stack>

          <Stack spacing={1} sx={{ maxWidth: 500 }}>
            {places?.map((x) => (
              <React.Fragment key={x.id}>
                <PlaceRowContent place={x} showTag sx={{ p: 2 }} />
                <Divider />
              </React.Fragment>
            ))}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={0} sm={8}>
        <MapPage
          places={places?.slice(0, 5) ?? []}
          setIsGoogleMapsLoaded={handleGoogleMapsLoaded}
        />
      </Grid>
    </Grid>
  );
};
