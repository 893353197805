import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { Place } from "features/place";

export interface PlaceMarkerProps {
  place: Place;
  lat: number;
  lng: number;
}

export const PlaceMarker = ({ place }: PlaceMarkerProps) => {
  console.log("place", place);
  const { imageUrl } = place;
  return (
    <Tooltip title={place.name}>
      <img style={{ width: 50 }} src="/pin.place.png" />
    </Tooltip>
    // <Stack sx={{ transform: "translate(-50%, -50%)" }}>
    //  <Box sx={{ position: "relative" }}>
    //   <img style={{ width: 50 }} src="/pin.place.png" />
    //   {imageUrl && (
    //     <img
    //       alt={place.name}
    //       style={{
    //         width: 40,
    //         height: 35,
    //         borderRadius: 4,
    //         objectFit: "cover",
    //         position: "absolute",
    //         top: 0,
    //         left: 4,
    //       }}
    //       src={imageUrl}
    //     />
    //   )}
    // </Box>
    // <Typography variant="subtitle1">{place.name}</Typography>
    // </Stack>
    // </Tooltip>
  );
};
