import React from 'react';
import { Link, LinkProps, Location, useLocation } from 'react-router-dom';

export interface ReferralLocationState {
	from: Location;
}

const isReferralState = (state: unknown): state is ReferralLocationState => {
	if (!state || typeof state !== 'object') return false;

	const from = (state as any).from;
	return from && typeof from === 'object';
}

export function useReferringPath(): string | undefined {
	const location = useLocation();

	if (isReferralState(location.state)) {
		return location.state.from.pathname;
	}

	return undefined;
}

export const LinkWithReferral: React.FC<LinkProps> =
	React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
		const location = useLocation();
		const fromState: ReferralLocationState = {
			from: location
		}

		return (
			<Link
				ref={ref}
				{...props}
				state={{
					...props.state,
					...fromState
				}}
			/>
		)
	}
)