import { Stack } from "@mui/material";
import { TabPanel } from "component/core";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useGetUser } from "features/user";
import { List, useGetListsLazy } from "features/list";
import { useGetTasteProfile } from "features/taste-profile/taste-profile.api";
import { useCombinedQueryState } from "app/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { LoadingIndicator } from "app/LoadingIndicator";
import { useTabNavigation } from "features/navigation";
import FriendListsPage from "features/friend/pages/FriendListsPage";
import { useConnection } from "features/connections";
import { RoomListPage } from "pages/room/RoomListPage";
import { DiscoverPage } from "pages/discover/DiscoverPage/DiscoverPage";
import { UserDetailPage } from "pages/user/UserDetailPage";

export default function HomePage() {
  const { session } = useConnection();
  // const { data: user, ...userQuery } = useGetUser(session?.userId ?? skipToken);
  // const { data: tasteProfile, ...tasteProfileQuery } = useGetTasteProfile(
  //   session?.userId ?? skipToken
  // );

  // const [lists, setLists] = useState<List[]>([]);
  // const [getLists] = useGetListsLazy();

  // const { isLoading } = useCombinedQueryState(userQuery, tasteProfileQuery);
  const { currentTabIndex } = useTabNavigation();

  // const title = useMemo(
  //   () => (user?.name ? `${user.name} | Taste Profile` : "My Taste Profile"),
  //   [user]
  // );

  // useEffect(() => {
  //   const fetch = async () => {
  //     const userId = session?.userId;
  //     if (userId) {
  //       const lists = await getLists(userId).unwrap();
  //       console.log("FIRST ", lists);
  //       setLists(lists);
  //     }
  //   };

  //   fetch();
  // }, [session, getLists]);

  // if (isLoading) {
  //   return <LoadingIndicator />;
  // }

  return (
    <Stack spacing={2}>
      {/* <Helmet>
        <title>Home</title>
        <meta property="og:title" content={title} />
        <meta name="og:description" content="Come check out my taste profile" />
        <meta
          property="og:image"
          content="https://main.d2k4al3gq1ayn4.amplifyapp.com/logo.png"
        />
      </Helmet> */}

      <TabPanel value={currentTabIndex} index={0}>
        <RoomListPage />
      </TabPanel>

      <TabPanel value={currentTabIndex} index={1}>
        <DiscoverPage />
      </TabPanel>

      <TabPanel value={currentTabIndex} index={2}>
        <FriendListsPage />
      </TabPanel>

      <TabPanel value={currentTabIndex} index={3}>
        <UserDetailPage userId={session?.userId} />
      </TabPanel>
    </Stack>
  );
}
