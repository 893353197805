import { Link, Stack, Typography } from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

const ShareSms = () => {
  return (
    <Link href={`sms:?body=${window.location.toString()}`}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          scale: 1.8,
          cursor: "pointer",
        }}
      >
        <QuestionAnswerIcon
          fontSize="large"
          sx={{
            backgroundColor: "#809b39",
            color: "#fff",
            borderRadius: 5,
            p: 1,
          }}
        />
        <Typography variant="body1" sx={{ color: "#000" }}>
          SMS
        </Typography>
      </Stack>
    </Link>
  );
};

export default ShareSms;
