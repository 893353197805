import { Place } from "features/place";
import GoogleMapReact from "google-map-react";
import { useEffect, useState } from "react";
import { PlaceMarker } from "./PlaceMarker";

const key = "AIzaSyAmybLal7OOFN66r6X6fwYhx9pF5mLnM60";

// Return map bounds based on list of places
const getMapBounds = (maps: any, places: Place[]) => {
  const bounds = new maps.LatLngBounds();

  places.forEach((x) => {
    bounds.extend(new maps.LatLng(x.lat, x.lng));
  });
  return bounds;
};

export interface MapPageProps {
  setIsGoogleMapsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  places: Place[];
  selectedPlace?: Place;
}

export const MapPage = ({
  setIsGoogleMapsLoaded,
  selectedPlace,
  places,
}: MapPageProps) => {
  // const isMobileOrTab = useMediaQuery(theme.breakpoints.down("md"));
  // const classes = useStyles({ isMobileOrTab });
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);

  useEffect(() => {
    if (!map || !maps || !places) {
      return;
    }
    // Get bounds by our places
    const bounds = getMapBounds(maps, places);
    // Fit map to bounds
    (map as any).fitBounds(bounds);
    // bindResizeListener(map, maps, bounds);
  }, [map, maps, places]);

  useEffect(() => {
    if (!map || !maps || !selectedPlace) {
      return;
    }
    const bounds = getMapBounds(maps, [selectedPlace]);
    var center = bounds.getCenter();
    const offset = 0.002;
    bounds.extend(
      new (window as any).google.maps.LatLng(
        center.lat() + offset,
        center.lng() + offset
      )
    );
    bounds.extend(
      new (window as any).google.maps.LatLng(
        center.lat() - offset,
        center.lng() - offset
      )
    );

    (map as any).fitBounds(bounds);
  }, [map, maps, selectedPlace]);

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "calc(100vh - 50px)", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          libraries: ["geometry", "places"],
          key,
        }}
        center={{ lat: 39.8283, lng: -28.5795 }}
        zoom={1}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          setMap(map);
          setMaps(maps);
          console.log("on google api loaded");
          setIsGoogleMapsLoaded(true);
        }}
      >
        {places.map((x) => (
          <PlaceMarker key={x.id} place={x} lat={x.lat ?? 0} lng={x.lng ?? 0} />
        ))}
      </GoogleMapReact>
    </div>
  );
};
