import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";

// React Toolkit and  Firebase
// https://blog.bitsrc.io/how-to-use-firestore-with-redux-in-a-react-application-f127d35adf3e
// https://orizens.com/blog/integration-of-firebase-firestore-with-redux-toolkit-query/

export const firebaseApi = createApi({
  reducerPath: "firebase",
  baseQuery: fakeBaseQuery(),
  endpoints: (builder) => ({}),
  tagTypes: ["rooms"],
});
