import { UserAvatar, UserAvatarSize, UserPreview } from "features/user";
import { FirestoreMessage } from "../message";
import { Stack, Typography } from "@mui/material";
import { AppAvatar } from "./AppAvatar";
import ReactMarkdown from "react-markdown";

export interface MessageAutomatedTextCellProps {
  message: FirestoreMessage;
  user?: UserPreview;
  isCurrentUser: boolean;
}

export const MessageAutomatedTextCell = ({
  message,
  user,
  isCurrentUser,
}: MessageAutomatedTextCellProps) => {
  return (
    <Stack
      direction={isCurrentUser ? "row-reverse" : "row"}
      alignItems="center"
      spacing={1}
    >
      {user ? (
        <UserAvatar user={user!} size={UserAvatarSize.small} />
      ) : (
        <AppAvatar />
      )}
      <ReactMarkdown>{message.content ?? ""}</ReactMarkdown>
    </Stack>
  );
};
