import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const WhiteTextField = styled(TextField)({
  width: 400,
  input: {
    height: 60,
    fontSize: "32px",
    color: "#ffffff",
    borderBottom: "1px solid #ffffff",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#fff",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#fff",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#fff",
  },
  "& .MuiInput-underline:hover": {
    borderBottom: "1px solid #fff",
  },
});
