import { UserAvatar, UserAvatarSize, UserPreview } from "features/user";
import { FirestoreMessage } from "../message";
import { Stack, Typography } from "@mui/material";
import { AppAvatar } from "./AppAvatar";

export interface MessageTextCellProps {
  message: FirestoreMessage;
  user?: UserPreview;
  isCurrentUser: boolean;
}

const MessageStyle = {
  left: {
    direction: "row",
    backgroundColor: "#f5f5f5",
    color: "#000",
    textAlign: "left",
  },
  right: {
    direction: "row-reverse",
    backgroundColor: "#231F35",
    color: "#FFF",
    textAlign: "right",
  },
};

export const MessageTextCell = ({
  message,
  user,
  isCurrentUser,
}: MessageTextCellProps) => {
  const style = isCurrentUser ? MessageStyle.right : MessageStyle.left;

  return (
    <Stack direction={style.direction as any} alignItems="center" spacing={1}>
      <UserAvatar user={user!} size={UserAvatarSize.small} />
      <Typography
        sx={{
          bgcolor: style.backgroundColor,
          color: style.color,
          textAlign: style.textAlign,
          pl: 2,
          pr: 2,
          pt: 1,
          pb: 1,
          borderRadius: 5,
        }}
      >
        {message.content}
      </Typography>
    </Stack>
  );
};
