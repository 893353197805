import { Chip } from "@mui/material";
import { RoomStatus } from "features/room";

export interface RoomStatusChipProps {
  status: RoomStatus;
}

const StatusTitle: Record<RoomStatus, string> = {
  new: "test",
  voting: "Voting Now",
  completed: "Voting Complete",
};

const BackgroundColor: Record<RoomStatus, string> = {
  new: "primary",
  voting: "primary",
  completed: "#fcba03",
};

const Color: Record<RoomStatus, string> = {
  new: "#fff",
  voting: "#fff",
  completed: "#000",
};

export const RoomStatusChip = ({ status }: RoomStatusChipProps) => {
  if (status === "new") {
    return <></>;
  }

  return (
    <Chip
      sx={{
        color: Color[status],
        background: BackgroundColor[status],
        fontSize: "11px",
        fontFamily: "PPMoriSemiBold",
      }}
      label={StatusTitle[status]}
      size="small"
    />
  );
};
