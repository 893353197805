import { Box, Divider, Stack } from "@mui/material";
import { RoomListNav } from "./RoomListNav/RoomListNav";
import { RoomDetail } from "features/room";
import { useState } from "react";
import { RoomDetailTabPage } from "./RoomDetailTabPage/RoomDetailTabPage";

export const RoomListPage = () => {
  const [selectedRoom, setSelectedRoom] = useState<RoomDetail>();
  return (
    <Stack direction="row">
      <RoomListNav
        sx={{ minWidth: 400 }}
        selectedRoom={selectedRoom}
        onSelect={(x) => setSelectedRoom(x)}
      />
      {/* <Box
        sx={{
          width: "1px",
          background: "#231F35",
          opacity: 0.1,
        }}
      /> */}
      <RoomDetailTabPage roomDetail={selectedRoom} sx={{ flex: 1 }} />
    </Stack>
  );
};
