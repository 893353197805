import { firebaseApi } from "app/firebase";
import { db } from "app/firebase/firebase";
import {
  Timestamp,
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { RoomStatus } from "./room.api";
import { FirestoreMessage } from "features/chat";

export interface FirestoreRoom {
  id: string;
  userIds: [string];
  status: RoomStatus;
  unreadNotificationsCount?: Map<string, number>;
  lastMessage?: FirestoreMessage;
  updatedAt?: Timestamp;
}

const roomFirestoreApi = firebaseApi.injectEndpoints({
  endpoints: (builder) => ({
    listenFirestoreRooms: builder.query<FirestoreRoom[], string>({
      queryFn: async () => {
        // const querySnapshot = await getDocs(roomsCollection);
        // const data = querySnapshot?.docs.map(
        //   (x) => x.data() as FirestoreRoom
        // );
        return { data: [] };
      },
      onCacheEntryAdded: async (
        userId,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) => {
        let unsubscribe = () => {};
        console.log("onCacheEntryAdded");
        try {
          await cacheDataLoaded;

          const q = query(
            collection(db, "rooms"),
            where("userIds", "array-contains", userId)
          );

          unsubscribe = onSnapshot(q, (snapshot) => {
            console.log(
              "change",
              snapshot.docs.map((x) => x.data() as FirestoreRoom)
            );
            updateCachedData((draft) => {
              return snapshot.docs
                .map((x) => x.data() as FirestoreRoom)
                .sort(
                  (a, b) =>
                    (b.updatedAt?.seconds ?? 0) - (a.updatedAt?.seconds ?? 0)
                );
            });
          });
        } catch (error) {
          console.error(error);
          throw error;
        }

        await cacheEntryRemoved;
        unsubscribe();
      },
      providesTags: ["rooms"],
    }),

    listenMessages: builder.query<FirestoreMessage[], string>({
      queryFn: async () => {
        return { data: [] };
      },
      onCacheEntryAdded: async (
        roomId,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) => {
        let unsubscribe = () => {};
        try {
          await cacheDataLoaded;

          const q = query(
            collection(db, `rooms/${roomId}/messages`),
            orderBy("createdAt")
          );

          unsubscribe = onSnapshot(q, (snapshot) => {
            updateCachedData((draft) => {
              return snapshot.docs.map((x) => x.data() as FirestoreMessage);
            });
          });
        } catch (error) {
          console.error(error);
          throw error;
        }

        await cacheEntryRemoved;
        unsubscribe();
      },
    }),
  }),
});

export const {
  listenFirestoreRooms: { useQuery: useListenFirestoreRooms },
  listenMessages: { useQuery: useListenMessages },
} = roomFirestoreApi.endpoints;
