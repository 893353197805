import { CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import {
  AsYouType,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js";
import { useMemo, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  MfaRequiredResponse,
  useCompleteMfaLogin,
  useStartMfaLogin,
} from "features/authentication";
import { WhiteTextField } from "component/input";
import { useConnection } from "features/connections";
import { Navigate } from "react-router-dom";
import { useReferringPath } from "features/routing";

interface NextIconProps {
  visible: boolean;
  loading: boolean;
  onClick: () => Promise<void>;
}

const NextIcon = ({ visible, loading, onClick }: NextIconProps) => {
  return loading ? (
    <CircularProgress sx={{ color: "#fff", mr: 1 }} size="24px" />
  ) : (
    <IconButton
      sx={{ color: "#fff", opacity: visible ? 1 : 0 }}
      onClick={onClick}
    >
      <ArrowForwardIcon />
    </IconButton>
  );
};

export const SplashLoginPage = () => {
  const [phoneNumberInput, setPhoneNumberInput] = useState("4158128951");
  const [confirmationCode, setConfirmationCode] = useState("");
  const fromPathName = useReferringPath();

  const [startMfaLogin, { isLoading: isStartMfaLoginLoading }] =
    useStartMfaLogin();
  const [completeMfaLogin, { isLoading: isLoginLoading }] =
    useCompleteMfaLogin();
  const { session, createSession } = useConnection();

  const [mfaResponse, setMfaResponse] = useState<MfaRequiredResponse>();

  const showConfirmationCode = useMemo(() => !!mfaResponse, [mfaResponse]);

  const isValidNumber = useMemo(
    () => isValidPhoneNumber(phoneNumberInput, "US"),
    [phoneNumberInput]
  );

  const phoneNumber = useMemo(() => {
    const phoneObj = parsePhoneNumber(phoneNumberInput, "US");

    return phoneObj.isValid() ? phoneObj.number : undefined;
  }, [phoneNumberInput]);

  const isValidConfirmationCode = useMemo(
    () => confirmationCode.length === 6,
    [confirmationCode]
  );

  if (session) return <Navigate to={fromPathName ?? "/home"} replace={true} />;

  const handleSubmit = async () => {
    if (showConfirmationCode) {
      await handleCompleteLogin();
    } else {
      await handleStartLogin();
    }
  };

  const handleStartLogin = async () => {
    if (!phoneNumber) {
      return;
    }

    const response = await startMfaLogin({ phoneNumber }).unwrap();
    setMfaResponse(response);
  };

  const handleCompleteLogin = async () => {
    if (!isValidConfirmationCode || !mfaResponse || !phoneNumber) {
      return;
    }

    const { userId, accessToken, refreshToken } = await completeMfaLogin({
      phoneNumber,
      confirmationCode,
      session: mfaResponse.session,
      challengeName: mfaResponse.challengeName,
    }).unwrap();

    createSession(userId, accessToken, refreshToken);
  };

  return (
    <Stack
      sx={{ background: "#4E34DA", color: "#FFF", height: "100vh", p: 20 }}
      component="form"
      onSubmit={handleSubmit}
    >
      <Stack sx={{ pb: 5 }}>
        <Typography variant="h3">Moonshotz</Typography>
        <Typography variant="h6">Eating out made simple</Typography>
      </Stack>

      {showConfirmationCode ? (
        <Stack spacing={1}>
          <Typography variant="body1">
            Sent code to number ending in{" "}
            {mfaResponse?.challengeParameters.CODE_DELIVERY_DESTINATION.substring(
              mfaResponse?.challengeParameters.CODE_DELIVERY_DESTINATION
                .length - 4
            )}
          </Typography>
          <WhiteTextField
            placeholder="Verification Code"
            variant="standard"
            value={confirmationCode}
            onChange={(e) => setConfirmationCode(e.target.value)}
            InputProps={{
              endAdornment: (
                <NextIcon
                  loading={isLoginLoading}
                  visible={isValidConfirmationCode}
                  onClick={handleCompleteLogin}
                />
              ),
            }}
          />
        </Stack>
      ) : (
        <WhiteTextField
          type="tel"
          placeholder="Phone Number"
          variant="standard"
          value={phoneNumberInput}
          onChange={(e) => {
            const num = new AsYouType("US").input(e.target.value as string);
            console.log(num);
            setPhoneNumberInput(num);
          }}
          InputProps={{
            startAdornment: (
              <Typography sx={{ fontSize: "32px", color: "#FFF", pr: 2 }}>
                +1
              </Typography>
            ),
            endAdornment: (
              <NextIcon
                loading={isStartMfaLoginLoading}
                visible={isValidNumber}
                onClick={handleStartLogin}
              />
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    </Stack>
  );
};
