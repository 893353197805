import { BottomSheet } from "react-spring-bottom-sheet";

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  EmailIcon,
  FacebookIcon,
} from "react-share";

// if setting up the CSS is tricky, you can add this to your page somewhere:
// <link rel="stylesheet" href="https://unpkg.com/react-spring-bottom-sheet/dist/style.css" crossorigin="anonymous">
import "react-spring-bottom-sheet/dist/style.css";
import { Divider, Stack, Typography } from "@mui/material";
import CopyToClipboardButton from "./CopyToClipboard";
import ShareSms from "./ShareSms";
import { useEffect, useState } from "react";
import { useCreateUrl } from "features/url";

export interface ShareSheetProps {
  path: string;
  isOpen: boolean;
  onDismiss: () => void;
}

export const ShareSheet = ({ path, isOpen, onDismiss }: ShareSheetProps) => {
  const [shortUrl, setShortUrl] = useState("");
  const [createUrl] = useCreateUrl();

  console.log("share sheet", path);
  useEffect(() => {
    const updateShortUrl = async () => {
      const url = await createUrl({ path }).unwrap();

      console.log("updateShortUrl", url.data.hash);
      setShortUrl(`https://${window.location.host}/s/${url.data.hash}`);
    };

    updateShortUrl();
  }, [path, createUrl]);

  return (
    <BottomSheet open={isOpen} onDismiss={onDismiss}>
      <Stack sx={{ p: 2, pb: 4 }} spacing={2}>
        <Typography variant="h5">Share With Friends</Typography>
        <Divider />
        <CopyToClipboardButton content={shortUrl} />
        <ShareSms />
        <EmailShareButton url={shortUrl}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <EmailIcon size={38} round />
            <Typography variant="body1">Email</Typography>
          </Stack>
        </EmailShareButton>
        <FacebookShareButton url={shortUrl}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <FacebookIcon size={38} round />
            <Typography variant="body1">Facebook</Typography>
          </Stack>
        </FacebookShareButton>
        <TwitterShareButton url={shortUrl}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TwitterIcon size={38} round />
            <Typography variant="body1">Twitter</Typography>
          </Stack>
        </TwitterShareButton>
      </Stack>
    </BottomSheet>
  );
};
