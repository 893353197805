// import PPMoriExtralight from "../../ffonts/PPMoriExtralight.otf";

import { createContext, useMemo, useState, useContext, ReactNode } from "react";
import { createTheme, PaletteMode, Theme } from "@mui/material";

export const PrimaryColor = "#4E34DA";

const getDesignTokens = (mode: PaletteMode) => ({
  typography: {
    fontFamily: "PPMoriRegular",
  },
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: {
            main: "#4E34DA",
          },
          background: {
            default: "#FFF",
            paper: "#FFF",
          },
          // divider: amber[200],
          text: {
            primary: "#000",
            secondary: "#000",
          },
          secondary: {
            main: "#231F35",
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: "#4E34DA",
          },
          secondary: {
            main: "#231F35",
          },
          // divider: deepOrange[700],
          background: {
            default: "#1E1E1E",
            paper: "#1E1E1E",
            active: "#2a2a2a",
          },
          text: {
            primary: "#fff",
            secondary: "#c6c6c6",
          },
        }),
  },
});

const Context = createContext({
  toggleColorMode: () => {},
  theme: {} as Partial<Theme>,
  mode: "light",
  isDarkMode: false,
});

export interface ColorModeContextProviderProps {
  children: ReactNode;
}

export function ColorModeContextProvider({
  children,
}: ColorModeContextProviderProps) {
  const [mode, setMode] = useState<PaletteMode>("light");
  // Update the theme only if the mode changes
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  const colorMode = useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === "light" ? "dark" : "light"
        );
      },
      mode,
      theme,
      isDarkMode: mode === "dark",
    }),
    [mode, theme]
  );
  return <Context.Provider value={colorMode}>{children}</Context.Provider>;
}

export const useColorMode = () => useContext(Context);

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#386A20",
//     },
//     text: {
//       primary: "#000",
//     },
//     secondary: {
//       main: "#749a9a",
//     },
//   },
// });
