import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useConnection } from "features/connections";
import { MapPage } from "features/map/MapPage";
import { useGetUserPlaces } from "features/user";

export interface DiscoverPageProps {}

export const DiscoverPage = () => {
  const { session } = useConnection();
  const { data: places } = useGetUserPlaces(session?.userId ?? skipToken);

  console.log("DiscoverPage", places);
  const handleGoogleMapsLoaded = () => {};

  return (
    <MapPage
      places={places?.slice(0, 5) ?? []}
      setIsGoogleMapsLoaded={handleGoogleMapsLoaded}
    />
  );
};
