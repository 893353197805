import { appApi } from "app/api";
import {
  dataTransformSchema,
  requiredNumber,
  requiredString,
  requiredUuid,
} from "app/schema";
import { placeSchema } from "features/place";
import { tagSchema } from "features/tag";
import * as yup from "yup";

export const tasteProfileSchema = yup.object({
  userId: requiredUuid,
  placeCount: requiredNumber,
  stateCount: requiredNumber,
  cityCount: requiredNumber,
  topTags: yup.array().of(tagSchema),
  topPlaces: yup.array().of(placeSchema),
  topPersonality: requiredString,
  topPersonalityDescription: requiredString,
  personalityNomad: requiredNumber,
  personalityAdventurer: requiredNumber,
  personalityEclectic: requiredNumber,
  personalityPlanner: requiredNumber,
  personalityHighRoller: requiredNumber,
  personalityCritic: requiredNumber,
  personalityNightOwl: requiredNumber,
  personalityTrendsetter: requiredNumber,
});

export interface TasteProfile extends yup.Asserts<typeof tasteProfileSchema> {}

const tasteProfileAPi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getTasteProfile: builder.query<TasteProfile, string>({
      query: (userId) => `/profiles/${userId}`,
      extraOptions: {
        schema: dataTransformSchema(tasteProfileSchema).nullable(),
      },
    }),
  }),
});

export const {
  getTasteProfile: { useQuery: useGetTasteProfile },
} = tasteProfileAPi.endpoints;

// class TasteProfileService extends ApiService {
//     async get(id: string) {
//       const {
//         data: { data },
//       } = await this.axiosInstance.get(`/profiles/${id}`);

//       return data as TasteProfile;
//     }
//   }
